import React from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import img01 from "../assets/images/img01.png";
import bg from "../assets/images/bg.jpeg";
import bg02 from "../assets/images/bg02.webp";
import img02 from "../assets/images/img02.png";
import img03 from "../assets/images/img03.png";
import img04 from "../assets/images/img04.png";
import img05 from "../assets/images/img05.jpg";
import img06 from "../assets/images/img06.jpg";
import img08 from "../assets/images/img08.jpg";

const Home = () => {
  return (
    <div className="min-h-screen">
      {/* Hero Section */}
      <section className="relative min-h-[500px] h-[100vh] max-h-screen overflow-hidden">
        <div className="absolute inset-0">
          <img
            src={bg02}
            alt="Luxury Limousine"
            className="w-full h-full object-cover"
          />
          <div className="absolute inset-0 bg-gradient-to-b from-black/70 to-black/50"></div>
        </div>
        <div className="relative h-full flex items-center justify-start">
          <div className="container mx-auto px-4 sm:px-6 lg:px-8">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
              className="max-w-3xl mx-auto md:mx-0 flex flex-col gap-4 sm:gap-6 md:gap-8"
            >
              <h1 className="text-4xl sm:text-5xl md:text-7xl lg:text-8xl font-bold text-white tracking-tight leading-tight">
                Luxury Transport <br />
                <span className="text-[#C8A45D]">Redefined</span>
              </h1>
              <p className="text-lg sm:text-xl md:text-2xl lg:text-3xl text-gray-200 leading-relaxed max-w-2xl">
                Premium transportation services in Sharjah and across the UAE,
                where sophistication meets exceptional service
              </p>
              <div className="flex flex-col sm:flex-row gap-3 sm:gap-4">
                <Link
                  to="/contact"
                  className="inline-block bg-[#C8A45D] text-white py-2 sm:py-3 md:py-4 px-6 sm:px-7 md:px-8 rounded-lg font-semibold hover:bg-white hover:text-[#1A1A1A] transition-all duration-300 text-sm sm:text-base md:text-lg shadow-lg transform hover:scale-105 text-center"
                >
                  Book Your Ride
                </Link>
                <Link
                  to="/services"
                  className="inline-block bg-transparent border-2 border-white text-white py-2 sm:py-3 md:py-4 px-6 sm:px-7 md:px-8 rounded-lg font-semibold hover:bg-white hover:text-[#1A1A1A] transition-all duration-300 text-sm sm:text-base md:text-lg text-center"
                >
                  Our Services
                </Link>
              </div>
            </motion.div>
          </div>
        </div>
      </section>

      {/* Services Preview */}
      <section className="py-20 md:py-32 bg-gray-50">
        <div className="container mx-auto px-6 md:px-8">
          <motion.h2
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            className="text-3xl md:text-5xl font-bold text-center mb-20 text-[#1A1A1A]"
          >
            Our Premium Services
          </motion.h2>
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8 md:gap-10">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: 0.2 }}
              className="relative group"
            >
              <div className="relative h-[400px] rounded-2xl overflow-hidden shadow-lg">
                <img
                  src={img02}
                  alt="Airport Transfers"
                  className="w-full h-full object-cover transform group-hover:scale-110 transition-transform duration-700"
                />
                <div className="absolute inset-0 bg-[#1A1A1A] bg-opacity-50 group-hover:bg-opacity-40 transition-all duration-300"></div>
                <div className="absolute inset-0 p-10 flex flex-col justify-end">
                  <h3 className="text-2xl font-bold text-white mb-4">
                    Airport Transfers
                  </h3>
                  <p className="text-white text-lg">
                    Professional and punctual airport transportation services
                  </p>
                </div>
              </div>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: 0.3 }}
              className="relative group"
            >
              <div className="relative h-[400px] rounded-2xl overflow-hidden shadow-lg">
                <img
                  src={img03}
                  alt="Corporate Travel"
                  className="w-full h-full object-cover transform group-hover:scale-110 transition-transform duration-700"
                />
                <div className="absolute inset-0 bg-[#1A1A1A] bg-opacity-50 group-hover:bg-opacity-40 transition-all duration-300"></div>
                <div className="absolute inset-0 p-10 flex flex-col justify-end">
                  <h3 className="text-2xl font-bold text-white mb-4">
                    Corporate Travel
                  </h3>
                  <p className="text-white text-lg">
                    Reliable and luxurious business transportation
                  </p>
                </div>
              </div>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: 0.4 }}
              className="relative group"
            >
              <div className="relative h-[400px] rounded-2xl overflow-hidden shadow-lg">
                <img
                  src={img04}
                  alt="Special Events"
                  className="w-full h-full object-cover transform group-hover:scale-110 transition-transform duration-700"
                />
                <div className="absolute inset-0 bg-[#1A1A1A] bg-opacity-50 group-hover:bg-opacity-40 transition-all duration-300"></div>
                <div className="absolute inset-0 p-10 flex flex-col justify-end">
                  <h3 className="text-2xl font-bold text-white mb-4">
                    Special Events
                  </h3>
                  <p className="text-white text-lg">
                    Make your special occasions truly memorable
                  </p>
                </div>
              </div>
            </motion.div>
          </div>
        </div>
      </section>

      {/* Why Choose Us */}
      <section className="py-24 md:py-32 bg-gray-50">
        <div className="container mx-auto px-6 md:px-8">
          <div className="grid md:grid-cols-2 gap-16 lg:gap-24 items-center">
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.6 }}
              className="relative h-[600px] rounded-2xl overflow-hidden shadow-2xl"
            >
              <img
                src={img05}
                alt="Luxury Service"
                className="absolute inset-0 w-full h-full object-cover"
              />
            </motion.div>
            <motion.div
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.6 }}
              className="space-y-10"
            >
              <h2 className="text-3xl md:text-5xl font-bold text-[#1A1A1A]">
                Why Choose Us
              </h2>
              <div className="space-y-8">
                <div className="bg-white p-8 rounded-xl shadow-lg hover:shadow-xl transition-shadow duration-300">
                  <h3 className="text-2xl font-bold mb-4 text-[#1A1A1A]">
                    Professional Chauffeurs
                  </h3>
                  <p className="text-[#333333] text-lg">
                    Experienced, courteous, and professionally trained drivers
                  </p>
                </div>
                <div className="bg-white p-8 rounded-xl shadow-lg hover:shadow-xl transition-shadow duration-300">
                  <h3 className="text-2xl font-bold mb-4 text-[#1A1A1A]">
                    Luxury Fleet
                  </h3>
                  <p className="text-[#333333] text-lg">
                    Modern, well-maintained luxury vehicles for your comfort
                  </p>
                </div>
                <div className="bg-white p-8 rounded-xl shadow-lg hover:shadow-xl transition-shadow duration-300">
                  <h3 className="text-2xl font-bold mb-4 text-[#1A1A1A]">
                    24/7 Service
                  </h3>
                  <p className="text-[#333333] text-lg">
                    Available around the clock for your transportation needs
                  </p>
                </div>
              </div>
            </motion.div>
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="relative py-32">
        <div className="absolute inset-0">
          <img
            src={img06}
            alt="Luxury Interior"
            className="w-full h-full object-cover"
          />
          <div className="absolute inset-0 bg-black bg-opacity-60"></div>
        </div>
        <div className="relative container mx-auto px-6 md:px-8">
          <div className="max-w-3xl mx-auto text-center">
            <motion.h2
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6 }}
              className="text-3xl md:text-5xl font-bold text-white mb-8"
            >
              Experience the Difference
            </motion.h2>
            <motion.p
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: 0.2 }}
              className="text-xl text-white mb-12 leading-relaxed"
            >
              Book your luxury transportation experience today and travel in
              style
            </motion.p>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: 0.4 }}
            >
              <Link
                to="/contact"
                className="inline-block bg-[#C8A45D] text-white py-4 px-10 rounded-lg font-semibold hover:bg-[#1A1A1A] transition-colors duration-300 text-lg shadow-lg"
              >
                Contact Us
              </Link>
            </motion.div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;
