import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import logo from "../../assets/images/logo.jpeg";

const navItems = [
  { title: "Home", path: "/" },
  { title: "About Us", path: "/about" },
  { title: "Services", path: "/services" },
  { title: "Contact Us", path: "/contact" },
];

const Navbar = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const location = useLocation();

  const isActive = (path: string) => {
    const normalizedPath = path.replace(/\/+$/, ""); // Remove trailing slash
    const normalizedLocation = location.pathname.replace(/\/+$/, "");
    return normalizedPath === normalizedLocation;
  };

  const getLinkClass = (path: string) =>
    `px-3 py-2 text-base font-medium ${
      isActive(path)
        ? "text-[#001f3f] font-semibold border-b-2 border-[#001f3f]"
        : "text-gray-600 hover:text-[#001f3f] transition-colors duration-300"
    }`;

  const getMobileLinkClass = (path: string) =>
    `block px-3 py-2 text-base font-medium ${
      isActive(path)
        ? "text-[#001f3f] font-semibold bg-gray-100"
        : "text-gray-600 hover:text-[#001f3f] hover:bg-gray-50 rounded-md"
    }`;

  return (
    <nav className="fixed top-0 left-0 right-0 z-50 bg-white border-b border-gray-200">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center h-20">
          {/* Logo */}
          <Link to="/" className="flex items-center">
            <img
              src={logo}
              alt="Sharjah Limo Logo"
              className="h-16 w-auto object-contain"
            />
          </Link>

          {/* Desktop Navigation */}
          <div className="hidden md:flex md:items-center md:space-x-8">
            {navItems.map((item) => (
              <Link
                key={item.title}
                to={item.path}
                className={getLinkClass(item.path)}
              >
                {item.title}
              </Link>
            ))}
          </div>

          {/* Mobile menu button */}
          <div className="flex md:hidden">
            <button
              type="button"
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-600 hover:text-[#001f3f] hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-[#001f3f]"
              onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
            >
              <span className="sr-only">Open main menu</span>
              {mobileMenuOpen ? (
                <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
              ) : (
                <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
              )}
            </button>
          </div>
        </div>
      </div>

      {/* Mobile menu */}
      <div
        className={`${
          mobileMenuOpen ? "block" : "hidden"
        } md:hidden bg-white border-b border-gray-200`}
      >
        <div className="px-2 pt-2 pb-3 space-y-1">
          {navItems.map((item) => (
            <Link
              key={item.title}
              to={item.path}
              className={getMobileLinkClass(item.path)}
              onClick={() => setMobileMenuOpen(false)}
            >
              {item.title}
            </Link>
          ))}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
