import React, { useState } from "react";
import { motion } from "framer-motion";
import bookingHero from "../assets/images/booking-hero.webp";

const Booking = () => {
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    pickup: "",
    dropoff: "",
    rideType: "",
    date: "",
    time: "",
  });

  const handleChange = (e: { target: { name: any; value: any } }) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    console.log("Booking submitted:", formData);
  };

  return (
    <div className="min-h-screen bg-white">
      {/* Hero Section */}
      <div className="relative h-[40vh] overflow-hidden">
        <div className="absolute inset-0">
          <img
            src={bookingHero}
            alt="Book a Ride"
            className="w-full h-full object-cover"
          />
          <div className="absolute inset-0 bg-gradient-to-b from-black/70 to-black/50"></div>
        </div>
        <div className="relative container mx-auto px-6 md:px-8 h-full flex items-center">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            className="text-white"
          >
            <h1 className="text-4xl md:text-6xl font-bold mb-4 tracking-tight">
              Book Your Ride
            </h1>
            <p className="text-lg md:text-xl text-gray-300 leading-relaxed max-w-2xl">
              Hassle-free booking for your next journey.
            </p>
          </motion.div>
        </div>
      </div>

      {/* Booking Form Section */}
      <div className="container mx-auto px-6 md:px-8 py-16">
        <div className="bg-white rounded-lg p-8 shadow-lg">
          <h2 className="text-3xl font-bold text-[#1A1A1A] mb-6">
            Ride Details
          </h2>
          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label
                htmlFor="name"
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                Full Name
              </label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#C8A45D] focus:border-transparent"
                placeholder="Your full name"
              />
            </div>

            <div>
              <label
                htmlFor="phone"
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                Phone Number
              </label>
              <input
                type="tel"
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                required
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#C8A45D] focus:border-transparent"
                placeholder="Your phone number"
              />
            </div>

            <div>
              <label
                htmlFor="pickup"
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                Pickup Location
              </label>
              <input
                type="text"
                id="pickup"
                name="pickup"
                value={formData.pickup}
                onChange={handleChange}
                required
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#C8A45D] focus:border-transparent"
                placeholder="Enter pickup location"
              />
            </div>

            <div>
              <label
                htmlFor="dropoff"
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                Drop-off Location
              </label>
              <input
                type="text"
                id="dropoff"
                name="dropoff"
                value={formData.dropoff}
                onChange={handleChange}
                required
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#C8A45D] focus:border-transparent"
                placeholder="Enter drop-off location"
              />
            </div>

            <div>
              <label
                htmlFor="rideType"
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                Ride Type
              </label>
              <select
                id="rideType"
                name="rideType"
                value={formData.rideType}
                onChange={handleChange}
                required
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#C8A45D] focus:border-transparent"
              >
                <option value="">Select a ride type</option>
                <option value="economy">Economy</option>
                <option value="business">Business</option>
                <option value="luxury">Luxury</option>
              </select>
            </div>

            <div className="flex gap-4">
              <div>
                <label
                  htmlFor="date"
                  className="block text-sm font-medium text-gray-700 mb-1"
                >
                  Date
                </label>
                <input
                  type="date"
                  id="date"
                  name="date"
                  value={formData.date}
                  onChange={handleChange}
                  required
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#C8A45D] focus:border-transparent"
                />
              </div>

              <div>
                <label
                  htmlFor="time"
                  className="block text-sm font-medium text-gray-700 mb-1"
                >
                  Time
                </label>
                <input
                  type="time"
                  id="time"
                  name="time"
                  value={formData.time}
                  onChange={handleChange}
                  required
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#C8A45D] focus:border-transparent"
                />
              </div>
            </div>

            <button
              type="submit"
              className="w-full bg-[#C8A45D] text-white py-3 px-6 rounded-lg font-semibold hover:bg-[#1A1A1A] transition-colors duration-300"
            >
              Confirm Booking
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Booking;
