import React, { useState } from "react";
import { motion } from "framer-motion";
import contactHero from "../assets/images/contact-us.webp";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    service: "",
    message: "",
  });

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // Handle form submission logic here
    console.log("Form submitted:", formData);
  };

  return (
    <div className="min-h-screen bg-white">
      {/* Hero Section */}
      <div className="relative h-[40vh] overflow-hidden">
        <div className="absolute inset-0">
          <img
            src={contactHero}
            alt="Contact Us"
            className="w-full h-full object-cover"
          />
          <div className="absolute inset-0 bg-gradient-to-b from-black/70 to-black/50"></div>
        </div>
        <div className="relative container mx-auto px-6 md:px-8 h-full flex items-center">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            className="text-white"
          >
            <h1 className="text-4xl md:text-6xl font-bold mb-4 tracking-tight">
              Contact Us
            </h1>
            <p className="text-lg md:text-xl text-gray-300 leading-relaxed max-w-2xl">
              Get in touch with us for premium transportation services
            </p>
          </motion.div>
        </div>
      </div>

      {/* Contact Form Section */}
      <div className="container mx-auto px-6 md:px-8 py-16">
        <div className="grid md:grid-cols-2 gap-12">
          {/* Contact Form */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            className="bg-white rounded-lg p-8 shadow-lg"
          >
            <h2 className="text-3xl font-bold text-[#1A1A1A] mb-6">
              Send Us a Message
            </h2>
            <form
              action="https://formspree.io/f/myzyplvy"
              method="POST"
              // onSubmit={handleSubmit}
              className="space-y-6"
            >
              <div>
                <label
                  htmlFor="name"
                  className="block text-sm font-medium text-gray-700 mb-1"
                >
                  Full Name
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#C8A45D] focus:border-transparent"
                  placeholder="Your full name"
                />
              </div>

              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700 mb-1"
                >
                  Email Address
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#C8A45D] focus:border-transparent"
                  placeholder="your@email.com"
                />
              </div>

              <div>
                <label
                  htmlFor="phone"
                  className="block text-sm font-medium text-gray-700 mb-1"
                >
                  Phone Number
                </label>
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  required
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#C8A45D] focus:border-transparent"
                  placeholder="Your phone number"
                />
              </div>

              <div>
                <label
                  htmlFor="service"
                  className="block text-sm font-medium text-gray-700 mb-1"
                >
                  Service Required
                </label>
                <select
                  id="service"
                  name="service"
                  value={formData.service}
                  onChange={handleChange}
                  required
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#C8A45D] focus:border-transparent"
                >
                  <option value="">Select a service</option>
                  <option value="airport">Airport Transfer</option>
                  <option value="corporate">Corporate Transportation</option>
                  <option value="events">VIP Events</option>
                  <option value="tours">Luxury Tours</option>
                  <option value="other">Other</option>
                </select>
              </div>

              <div>
                <label
                  htmlFor="message"
                  className="block text-sm font-medium text-gray-700 mb-1"
                >
                  Message
                </label>
                <textarea
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  required
                  rows={4}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-[#C8A45D] focus:border-transparent"
                  placeholder="Tell us about your requirements"
                ></textarea>
              </div>

              <button
                type="submit"
                className="w-full bg-[#C8A45D] text-white py-3 px-6 rounded-lg font-semibold hover:bg-[#1A1A1A] transition-colors duration-300"
              >
                Send Message
              </button>
            </form>
          </motion.div>

          {/* Contact Information */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.2 }}
            className="space-y-8"
          >
            <div>
              <h2 className="text-3xl font-bold text-[#1A1A1A] mb-6">
                Contact Information
              </h2>
              <div className="space-y-4 text-gray-600">
                <p className="flex items-center">
                  <span className="font-semibold w-24">Email:</span>
                  <a
                    href="mailto:info@stsc.ae"
                    className="hover:text-[#C8A45D]"
                  >
                    info@stsc.ae
                  </a>
                </p>
                <p className="flex items-center">
                  <span className="font-semibold w-24">Phone:</span>
                  <a href="tel:+97165279378" className="hover:text-[#C8A45D]">
                    +971 6 527 9378
                  </a>
                </p>
                <p className="flex items-center">
                  <span className="font-semibold w-24">Location:</span>
                  <span>Sharjah, United Arab Emirates</span>
                </p>
              </div>
            </div>

            <div>
              <h3 className="text-xl font-bold text-[#1A1A1A] mb-4">
                Business Hours
              </h3>
              <div className="space-y-2 text-gray-600">
                <p className="flex justify-between items-center">
                  <span>Monday - Friday:</span>
                  <span className="font-medium text-[#1A1A1A]">
                    7:00 AM – 3:00 PM
                  </span>
                </p>
                <p className="flex justify-between items-center">
                  <span>Saturday:</span>
                  <span className="font-medium text-red-600">Closed</span>
                </p>
                <p className="flex justify-between items-center">
                  <span>Sunday:</span>
                  <span className="font-medium text-red-600">Closed</span>
                </p>
                <p className="mt-4 text-sm text-gray-500 italic">
                  * For after-hours transportation services and emergencies,
                  please contact our 24/7 booking line
                </p>
              </div>
            </div>

            <div className="bg-gray-100 p-6 rounded-lg">
              <h3 className="text-xl font-bold text-[#1A1A1A] mb-4">
                Quick Response
              </h3>
              <p className="text-gray-600 mb-4">
                We aim to respond to all inquiries within 2 hours during
                business hours. For urgent matters, please call us directly.
              </p>
            </div>

            {/* Map Section */}
            <div className="mt-8">
              <div className="w-full h-[300px] rounded-lg overflow-hidden shadow-lg">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3609.0408531465287!2d55.62136457536573!3d25.280610083864843!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ef58dc04d602f07%3A0xd12aaebc0e598c1c!2sSharjah%20Transport%20Solutions%20LLC!5e0!3m2!1sen!2sae!4v1708342831037!5m2!1sen!2sae"
                  width="100%"
                  height="100%"
                  style={{ border: 0 }}
                  allowFullScreen={true}
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                  title="Sharjah Transport Solutions LLC Location"
                ></iframe>
              </div>
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
